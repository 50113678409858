var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{staticClass:"form-row"},[_c('sgv-input-text',{staticClass:"col-12",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"ชื่อ","validations":[
        {text: 'required!', value: _vm.v.formData.name.$dirty && _vm.v.formData.name.$error}
      ]},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),_c('sgv-input-currency',{staticClass:"col-4",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"ค่าน้อยสุด","precision":2,"validations":[
        {text: 'required!', value: _vm.v.formData.options.minValue.$dirty && _vm.v.formData.options.minValue.$error}
      ]},model:{value:(_vm.formData.options.minValue),callback:function ($$v) {_vm.$set(_vm.formData.options, "minValue", $$v)},expression:"formData.options.minValue"}}),_c('sgv-input-text',{staticClass:"col-4",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"ค่า","validations":[
        {text: 'required!', value: _vm.v.formData.options.value.$dirty && _vm.v.formData.options.value.$error}
      ]},model:{value:(_vm.formData.options.value),callback:function ($$v) {_vm.$set(_vm.formData.options, "value", $$v)},expression:"formData.options.value"}}),_c('sgv-input-currency',{staticClass:"col-4",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"ค่าสูงสุด","precision":2,"validations":[
        {text: 'required!', value: _vm.v.formData.options.value.$dirty && _vm.v.formData.options.value.$error}
      ]},model:{value:(_vm.formData.options.maxValue),callback:function ($$v) {_vm.$set(_vm.formData.options, "maxValue", $$v)},expression:"formData.options.maxValue"}}),_c('DocConfigFormInventory',{staticClass:"col-12",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"placeholder":"เลือกรายการ...","select":"id","docType":_vm.docType,"templateType":_vm.templateType,"types":['payroll'],"validations":[
        {text: 'required!', value: _vm.v.formData.inventoryId.$dirty && _vm.v.formData.inventoryId.$error}
      ]},scopedSlots:_vm._u([{key:"default",fn:function(option){return [_vm._v(" "+_vm._s(option.inventory.code)+" ("+_vm._s(option.inventory.name)+") ")]}}]),model:{value:(_vm.formData.inventoryId),callback:function ($$v) {_vm.$set(_vm.formData, "inventoryId", $$v)},expression:"formData.inventoryId"}}),_c('DocConfigFormAccount',{staticClass:"col-12",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"บัญชีค่าใช้จ่าย","placeholder":"เลือกรายการ...","select":"id","docType":_vm.docType,"templateType":_vm.templateType,"validations":[
        {text: 'required!', value: _vm.v.formData.accountId.$dirty && _vm.v.formData.accountId.$error}
      ]},scopedSlots:_vm._u([{key:"default",fn:function(option){return [_vm._v(" "+_vm._s(option.account.code)+" ("+_vm._s(option.account.name)+") ")]}}]),model:{value:(_vm.formData.accountId),callback:function ($$v) {_vm.$set(_vm.formData, "accountId", $$v)},expression:"formData.accountId"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }