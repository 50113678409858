<template>
  <div class="">
    <div class="form-row">
      <sgv-input-text
        :disabled="$auth.disabled(method)"
        label="ชื่อ"
        class="col-12"
        v-model="formData.name"
        :validations="[
          {text: 'required!', value: v.formData.name.$dirty && v.formData.name.$error}
        ]">
      </sgv-input-text>

      <sgv-input-currency
        :disabled="$auth.disabled(method)"
        label="ค่าน้อยสุด"
        class="col-4"
        :precision="2"
        v-model="formData.options.minValue"
        :validations="[
          {text: 'required!', value: v.formData.options.minValue.$dirty && v.formData.options.minValue.$error}
        ]">
      </sgv-input-currency>

      <sgv-input-text
        :disabled="$auth.disabled(method)"
        label="ค่า"
        class="col-4"
        v-model="formData.options.value"
        :validations="[
          {text: 'required!', value: v.formData.options.value.$dirty && v.formData.options.value.$error}
        ]">
      </sgv-input-text>

      <sgv-input-currency
        :disabled="$auth.disabled(method)"
        label="ค่าสูงสุด"
        class="col-4"
        :precision="2"
        v-model="formData.options.maxValue"
        :validations="[
          {text: 'required!', value: v.formData.options.value.$dirty && v.formData.options.value.$error}
        ]">
      </sgv-input-currency>

      <DocConfigFormInventory
        :disabled="$auth.disabled(method)"
        placeholder="เลือกรายการ..."
        class="col-12"
        select="id"
        :docType="docType"
        :templateType="templateType"
        v-model="formData.inventoryId"
        :types="['payroll']"
        :validations="[
          {text: 'required!', value: v.formData.inventoryId.$dirty && v.formData.inventoryId.$error}
        ]">
        <template slot-scope="option">
          {{option.inventory.code}} ({{option.inventory.name}})
        </template>
      </DocConfigFormInventory>

      <DocConfigFormAccount
        :disabled="$auth.disabled(method)"
        label="บัญชีค่าใช้จ่าย"
        placeholder="เลือกรายการ..."
        class="col-12"
        select="id"
        :docType="docType"
        :templateType="templateType"
        v-model="formData.accountId"
        :validations="[
          {text: 'required!', value: v.formData.accountId.$dirty && v.formData.accountId.$error}
        ]">
        <template slot-scope="option">
          {{option.account.code}} ({{option.account.name}})
        </template>
      </DocConfigFormAccount>
    </div>
  </div>
</template>

<script>
import DocConfigFormInventory from '@/views/doc_core/components/DocConfigFormInventory'
import DocConfigFormAccount from '@/views/doc_core/components/DocConfigFormAccount'

export default {
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    },
    v: {
      type: Object,
      required: true
    }
  },
  components: {
    DocConfigFormInventory,
    DocConfigFormAccount
  }
}
</script>

<style lang="css">
</style>
